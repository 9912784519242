import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Box, IconButton, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InfoIcon from '@mui/icons-material/Info';
import MDButton from 'components/MDButton';

function ConfigurationSendNotification({
  open,
  onClose,
  sendBy,
  selectedSendBy,
  handleSendByChange,
  templateWhatsapp,
  templateEmail,
  selectedTemplateWhatsApp,
  setSelectedTemplateWhatsApp,
  selectedTemplateEmail,
  setSelectedTemplateEmail,
  orderVariables,
  handleOrderVariables,
}) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        style: {
          height: '80vh',
          width: '100vh',
        },
      }}
    >
      <DialogTitle>Configurações</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sendBy}
              value={sendBy.find((option) => option.value === selectedSendBy)}
              getOptionLabel={(option) => option.label}
              onChange={handleSendByChange}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enviar por"
                  placeholder="Selecione por onde enviar"
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      height: 10,
                    }
                  }}
                />
              )}
              sx={{ marginTop: '10px' }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.label}
                </Box>
              )}
            />
            {selectedSendBy ? (
              <>
                <Box display="flex" alignItems="center" sx={{ marginTop: '10px' }}>
                  <TextField
                    label="Ordem das variáveis (whatsapp)"
                    fullWidth
                    onChange={(e) => handleOrderVariables(e.target.value)}
                    style={{ display: selectedSendBy === 'whatsapp' || selectedSendBy === 'both' ? 'block' : 'none' }}
                    value={orderVariables ? orderVariables : ''}
                  />
                  <Tooltip title="Disponíveis: {nome_cliente}, {tipo_boleto}, {valor}, {data_vencimento}, {cod_barras}, {pix_copia_cola}"
                    style={{ display: selectedSendBy === 'whatsapp' || selectedSendBy === 'both' ? 'block' : 'none' }}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Autocomplete
                  disablePortal
                  id="combo-box-template"
                  options={Array.isArray(templateWhatsapp) ? templateWhatsapp : []}
                  fullWidth
                  getOptionLabel={(option) => `${option.tag} - ${option.is_template ? 'Aprovado' : 'Não Aprovado'}`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{ marginBottom: '2px' }}>
                      {`${option.tag} - ${option.is_template ? 'Aprovado' : 'Não Aprovado'}`}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Templates de WhatsApp"
                      placeholder="Selecione um template"
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          height: 10,
                        }
                      }}
                    />
                  )}
                  onChange={(event, value) => setSelectedTemplateWhatsApp(value)}
                  sx={{ marginTop: '10px' }}
                  style={{ display: selectedSendBy === 'whatsapp' || selectedSendBy === 'both' ? 'block' : 'none' }}
                  value={selectedTemplateWhatsApp ? selectedTemplateWhatsApp : null}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-email"
                  options={templateEmail}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => setSelectedTemplateEmail(value)}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Templates de Email"
                      placeholder="Selecione um template"
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          height: 10,
                        }
                      }}
                    />
                  )}
                  sx={{ marginTop: '10px' }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.title}
                    </Box>
                  )}
                  style={{ display: selectedSendBy === 'email' || selectedSendBy === 'both' ? 'block' : 'none' }}
                  value={selectedTemplateEmail ? selectedTemplateEmail : null}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="error" fullWidth>
          Fechar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfigurationSendNotification;
