import React, { useState, useEffect } from 'react'
import { Grid, Card, TextField, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Autocomplete from '@mui/material/Autocomplete'
import DataTable from 'examples/Tables/DataTable'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import axios from 'axios'
import config from 'layouts/utils/config'
import auth from 'layouts/utils/auth'
import { format } from 'date-fns'
import './styles.css'

function Relatorio() {
  const [sessionData, setSessionData] = useState([])
  const [sessionSuccess, setSessionSuccess] = useState([])
  const [sessionFail, setSessionFail] = useState([])
  const [selectedSession, setSelectedSession] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [valueProgress, setValueProgress] = useState(0)
  const [maxProgress, setMaxProgress] = useState(0)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [expandedRow, setExpandedRow] = useState(null)

  const getSessionData = async () => {
    try {
      const response = await axios.get(`${config.base_api_url}/reports/session/`, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      })
      setSessionData(response.data)
    } catch (error) {
      console.error('Error fetching session data:', error)
    }
  }

  useEffect(() => {
    if (selectedSession) {
      const sucesso = selectedSession.reports_detalhes.filter(detalhe => detalhe.status_notification)
      const falhas = selectedSession.reports_detalhes.filter(detalhe => !detalhe.status_notification)
      setSessionSuccess(sucesso)
      setSessionFail(falhas)
    } else {
      setSessionSuccess([])
      setSessionFail([])
    }
  }, [selectedSession])

  const handleExpandTicket = (nome_cliente) => {
    setExpandedRow(expandedRow === nome_cliente ? null : nome_cliente)
  }

  const renderGroupedTickets = () => {
    if (!Array.isArray(sessionFail) || sessionFail.length === 0) {
      return null
    }

    return sessionFail.map(cliente => (
      <Dialog 
        key={`${cliente.nome_cliente}-${Math.random().toString(36).substr(2, 9)}`} 
        open={expandedRow === cliente.nome_cliente} 
        onClose={() => handleExpandTicket(cliente.nome_cliente)}
        maxWidth="lg"
      >
        <DialogTitle>
          <MDBox
            mx={0.2}
            py={1}
            px={1}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Boletos de {cliente.nome_cliente}
            </MDTypography>
          </MDBox>
        </DialogTitle>
        <DialogContent>
          <MDBox pt={1} className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Código de Barras</th>
                  <th>Link</th>
                  <th>Parcela</th>
                  <th>Valor</th>
                  <th>Emissão</th>
                  <th>Vencimento</th>
                </tr>
              </thead>
              <tbody>
                {
                  cliente.boletos.map((boleto, index) => (
                    <tr key={index}>
                      <td>{boleto.cod_cliente}</td>
                      <td>{boleto.cod_barras}</td>
                      <td>
                        <a 
                          href={boleto.link} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }} 
                          title={boleto.link}
                        >
                          {boleto.link}
                        </a>
                      </td>
                      <td>{boleto.parcela}</td>
                      <td>{boleto.valor}</td>
                      <td>{boleto.data_emissao}</td>
                      <td>{boleto.data_vencimento}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </MDBox>
          <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
            <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
            <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>{cliente.boletos.length}</p>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => handleExpandTicket(cliente.nome_cliente)} color="error" fullWidth>
            Fechar
          </MDButton>
        </DialogActions>
      </Dialog>
    ))
  }

  // Adicionar função para renderizar boletos enviados com sucesso
  const renderGroupedSuccessTickets = () => {
    if (!Array.isArray(sessionSuccess) || sessionSuccess.length === 0) {
      return null
    }

    return sessionSuccess.map(cliente => (
      <Dialog 
        key={`${cliente.nome_cliente}-${Math.random().toString(36).substr(2, 9)}`} 
        open={expandedRow === cliente.nome_cliente} 
        onClose={() => handleExpandTicket(cliente.nome_cliente)}
        maxWidth="lg"
      >
        <DialogTitle>
          <MDBox
            mx={0.2}
            py={1}
            px={1}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Boletos de {cliente.nome_cliente}
            </MDTypography>
          </MDBox>
        </DialogTitle>
        <DialogContent>
          <MDBox pt={1} className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Código de Barras</th>
                  <th>Link</th>
                  <th>Parcela</th>
                  <th>Valor</th>
                  <th>Emissão</th>
                  <th>Vencimento</th>
                </tr>
              </thead>
              <tbody>
                {
                  cliente.boletos.map((boleto, index) => (
                    <tr key={index}>
                      <td>{boleto.cod_cliente}</td>
                      <td>{boleto.cod_barras}</td>
                      <td>
                        <a 
                          href={boleto.link} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }} 
                          title={boleto.link}
                        >
                          {boleto.link}
                        </a>
                      </td>
                      <td>{boleto.parcela}</td>
                      <td>{boleto.valor}</td>
                      <td>{boleto.data_emissao}</td>
                      <td>{boleto.data_vencimento}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </MDBox>
          <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
            <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
            <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>{cliente.boletos.length}</p>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => handleExpandTicket(cliente.nome_cliente)} color="error" fullWidth>
            Fechar
          </MDButton>
        </DialogActions>
      </Dialog>
    ))
  }

  const handleSendNotifications = async () => {
    const source = axios.CancelToken.source()
    setCancelTokenSource(source)
    setIsSending(true)
    setValueProgress(0)
    setMaxProgress(sessionFail.length)
    const dateTimeStart = new Date().toLocaleString('sv-SE', { timeZone: 'America/Sao_Paulo' }).replace(' ', 'T')
    let report = []

    for (const ticket of sessionFail) {
      const email = []
      const contato = []
    
      if (ticket.contato_cliente) {
        const contatoFormatado = parseInt(ticket.contato_cliente.replace(/[^\d]/g, ''), 10)
        if (!isNaN(contatoFormatado)) {
          contato.push(contatoFormatado)
        }
      }
      
      if (ticket.email) {
        email.push(ticket.email)
      }
      try {
        const payload = {
          'cod_boleto': ticket.cod_boleto,
          'cod_barras': ticket.cod_barras,
          'nome_cliente': ticket.nome_cliente,
          'contato_cliente': contato,
          'email': email,
          'data_vencimento': format(new Date(ticket.data_vencimento), 'dd/MM/yyyy'),
          'data_vencimento_original': format(new Date(ticket.data_vencimento), 'dd/MM/yyyy'),
          'data_emissao': format(new Date(ticket.data_emissao), 'dd/MM/yyyy'),
          'boletos': ticket.boletos,
          'type': ticket.type,
          'template_whatsapp_id': ticket.type === 'whatsapp' || ticket.type === 'both' ? ticket.template_whatsapp_id : null,
          'template_whatsapp_tag': ticket.type === 'whatsapp' || ticket.type === 'both' ? ticket.template_whatsapp_tag : null,
          'template_whatsapp_params': ticket.type === 'whatsapp' || ticket.type === 'both' ? ticket.template_whatsapp_params : null,
          'template_email': ticket.type === 'email' || ticket.type === 'both' ? ticket.template_email : null,
          'status_notification' : ticket.status_notification
        }

        const response = await axios.post(`${config.base_api_url}/customer/send/`, payload, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`
          }
        })
        if (Array.isArray(response.data)) {
          response.data.map(item => 
            report.push(item.report_id)
          )
        } else {
          report.push(response.data.report_id)
        }
        setValueProgress((prevProgress) => prevProgress + 1)
      } catch (error) {
        setAlertTitle("Erro")
        setAlertMessage("Erro ao enviar boleto: " + ticket.cod_boleto)
        setAlertOpen(true)
      }
    }

    const dateTimeEnd = new Date().toLocaleString('sv-SE', { timeZone: 'America/Sao_Paulo' }).replace(' ', 'T')

    try {
      let type = 'both'

      if (sessionFail.some(item => item.type === 'whatsapp') && 
          !sessionFail.some(item => item.type === 'email')) {
          type = 'whatsapp'
      } else if (!sessionFail.some(item => item.type === 'whatsapp') && 
                 sessionFail.some(item => item.type === 'email')) {
          type = 'email'
      }
      
      const reportData = {
        report: report,
        date_time_start: dateTimeStart,
        date_time_end: dateTimeEnd,
        type: type
      }

      await axios.post(`${config.base_api_url}/reports/session/`, reportData, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      setAlertTitle("Sucesso")
      setAlertMessage("Boletos enviados com sucesso.")
      setAlertOpen(true)
      setIsSending(false)
    } catch (error) {
      setAlertTitle("Erro")
      setAlertMessage("Erro ao registrar sessão.")
      setAlertOpen(true)
    }
  }

  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operação cancelada pelo usuário.')
      setIsSending(false)
    }
  }

  const handleTabClick = (index) => {
    setActiveTab(index)
  }

  function getSendIcon(type) {
    if (type === 'whatsapp') {
      return <WhatsAppIcon />
    } else if (type === 'email') {
      return <EmailIcon />
    } else if (type === 'both') {
      return (
        <Box display="flex">
          <WhatsAppIcon />
          <EmailIcon />
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DashboardLayout>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDBox
                      mx={0.2}
                      mt={-2.2}
                      py={1}
                      px={1}
                      variant="gradient"
                      bgColor="warning"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Filtro de Sessões
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={sessionData}
                      color="info"
                      onOpen={() => getSessionData()}
                      onChange={(_, newValue) => setSelectedSession(newValue)}
                      getOptionLabel={(option) => `${option.type === 'both' ? 'Ambos' : option.type === 'whatsapp' ? 'WhatsApp' : option.type === 'email' ? 'E-mail' : ''}: ${option.date_time_start} - ${option.date_time_end}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sessões"
                          placeholder="Selecione uma sessão de notificação"
                          sx={{
                            '& .MuiOutlinedInput-input': {
                              height: 10,
                            }
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        '& .MuiAutocomplete-tag': {
                          backgroundColor: '#F9A817',
                          borderColor: '#F9A817',
                          color: '#FFFFFF',
                        },
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={`${option.id}-${option.date_time_start}`}>
                          {`${option.type === 'both' ? 'Ambos' : option.type === 'whatsapp' ? 'WhatsApp' : option.type === 'email' ? 'E-mail' : ''}: ${option.date_time_start} - ${option.date_time_end}`}
                        </Box>
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox className="tab-header">
                  <div
                    onClick={() => handleTabClick(0)}
                    className={activeTab === 0 ? 'active success' : ''}
                  >
                    Enviados com sucesso
                  </div>
                  <div
                    onClick={() => handleTabClick(1)}
                    className={activeTab === 1 ? 'active failure' : ''}
                  >
                    Falhas ao enviar
                  </div>
                </MDBox>
                <div className={`tab-content ${activeTab === 0 ? 'active success' : ''}`}>
                  <MDBox pt={1} className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Resposta</th>
                          <th>Cliente</th>
                          <th>Enviado por</th>
                          <th>Para</th>
                          <th>Ver Boletos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionSuccess.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <p onClick={() => {
                                setAlertTitle("Detalhes")
                                setAlertMessage(row.message_returned)
                                setAlertOpen(true)
                              }} style={{ cursor: 'pointer' }}>
                                Ver
                              </p>
                            </td>
                            <td>{row.nome_cliente}</td>
                            <td>{row.type}</td>
                            <td>{row.type === "email" ? row.email : row.contato_cliente}</td>
                            <td>
                              <MDButton onClick={() => handleExpandTicket(row.nome_cliente)} color="info">
                                Ver ({row.boletos.length})
                              </MDButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MDBox>
                  <MDBox px={1} py={1} display="flex" justifyContent="space-between">
                    <MDBox px={1} py={1} display="flex" justifyContent="center" alignItems="center">
                    </MDBox>
                    <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
                      <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
                      <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>{sessionSuccess.length}</p>
                    </MDBox>
                  </MDBox>
                </div>
                <div className={`tab-content ${activeTab === 1 ? 'active failure' : ''}`}>
                  <MDBox pt={1} className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Resposta</th>
                          <th>Cliente</th>
                          <th>Enviado por</th>
                          <th>Para</th>
                          <th>Ver Boletos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionFail.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <p onClick={() => {
                                setAlertTitle("Detalhes")
                                setAlertMessage(row.message_returned)
                                setAlertOpen(true)
                              }} style={{ cursor: 'pointer' }}>
                                Ver
                              </p>
                            </td>
                            <td>{row.nome_cliente}</td>
                            <td>{row.type}</td>
                            <td>{row.type === "email" ? row.email : row.contato_cliente}</td>
                            <td>
                              <MDButton onClick={() => handleExpandTicket(row.nome_cliente)} color="info">
                                Ver ({row.boletos.length})
                              </MDButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MDBox>
                  <MDBox px={1} py={1} display="flex" justifyContent="space-between">
                    <MDBox px={1} py={1} display="flex" justifyContent="center" alignItems="center">
                      <MDButton color="success" startIcon={getSendIcon(selectedSession?.type)} onClick={handleSendNotifications}>Reenviar Falhas</MDButton>
                    </MDBox>
                    <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
                      <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
                      <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>{sessionFail.length}</p>
                    </MDBox>
                  </MDBox>
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>

      {renderGroupedTickets()}
      {renderGroupedSuccessTickets()}

      <Dialog open={isSending} PaperProps={{style:{width: '100vh'}}}>
        <DialogTitle>Enviando...</DialogTitle>
        <DialogContent>
          <progress value={valueProgress} max={maxProgress} className="styled-progress" />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel} color="error" fullWidth>Cancelar</MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertMessage}</DialogContent>
        <DialogActions>
          <MDButton onClick={() => setAlertOpen(false)} color="info" fullWidth>Fechar</MDButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}

export default Relatorio